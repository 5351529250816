import { Pipe, PipeTransform } from '@angular/core';

import { getIonIconUrl } from '@sulser-print/utils/icon.utils';

@Pipe({
	name: 'ionIcon',
	standalone: true,
})
export class IonIconPipe implements PipeTransform {
	transform(value: string) {
		return getIonIconUrl(value);
	}
}
